import styled from 'styled-components';

export const HeaderNavbar = styled.div`
  height: 55px;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0;
  background-color: grey;
  overflow-x: hidden;
  padding-left: 65px;
  padding-top: auto;
  color: white;
`;

export const AppTitle = styled.span`
  position: absolute;
  top: 10%;
  display: block;
`;

export const LinkContainer = styled.div`
    top: 50%;
    display: flex;
    position: absolute;
    align-items: right;
    justify-content: right;
    color: white;
`

export const Link = styled.a`
  padding-right: 10px;
  text-decoration: none !important;
  font-size: 1 rem;
  color: white;
  display: block;
  transition: 0.2s;
  outline: none;

  &:hover {
    color: orange;
  }
`;

export const ToggleHide = styled.span`
  display: flex;
  padding: auto;
`;

export const Righter = styled.span`
  position: absolute;
  right: 30px;
  top: 30%;
  text-align: center;

  & a {
    padding: 0px;
    font-size: var(--navbar-link-font-size-smaller);
  }
`;