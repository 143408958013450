/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  HeaderNavbar,
  AppTitle,
  Link,
  LinkContainer,
  ToggleHide,
  Righter,
} from './styles/HeaderNav.styles';
import { AppContext } from '../../utils';
import { EXPORT_IMAGE } from '../../actions';
import cmsData from '../../cms';
import { SAVE_FILE, OPEN_FILE } from '../../actions/actions';

const HeaderNav = ({
  hasSaveFileOption,
  hasOpenFileOption,
  hasPrintOption,
  hasExportOption,
  hasPresentationModeOption,
  hasRighter,
  ...props
}) => {
  const { dispatch } = useContext(AppContext);
  const {
    l_app_title,
    l_app_version,
    l_save,
    l_open,
    l_export_to_svg,
    l_presentation_mode,
    l_back_to_blog,
    url_blog,
  } = cmsData;

  return (
    <>
      <HeaderNavbar id="headerbar" {...props}>
       

        <ToggleHide id="toggle-hide">
          <Divider />
          <AppTitle>
            <b>{l_app_title}</b> <i>{l_app_version}</i>
          </AppTitle>
          <Divider />
          <LinkContainer>
          {hasSaveFileOption && (
            <Link
              href="#!"
              onClick={() => dispatch({ action: SAVE_FILE })}
            >
              <Icon icon={IconNames.DOCUMENT} iconSize={15} /> {l_save}
            </Link>
          )}
          {hasOpenFileOption && (
            <Link
              href="#!"
              onClick={() =>
                dispatch({ action: OPEN_FILE, payload: { callback: dispatch } })
              }
            >
              <Icon icon={IconNames.DOCUMENT_OPEN} iconSize={15} /> {l_open}
            </Link>
          )}
          {hasPrintOption && (
            <Link href="#!" onClick={() => window.print()}>
              <Icon icon={IconNames.PRINT} iconSize={15} /> Print/to PDF
            </Link>
          )}
          {hasExportOption && (
            <Link
              href="#!"
              onClick={() => dispatch({ action: EXPORT_IMAGE })}
            >
              <Icon icon={IconNames.EXPORT} iconSize={20} /> {l_export_to_svg}
            </Link>
          )}
          {hasPresentationModeOption && (
            <Link href="#!">
              <Icon icon={IconNames.PRESENTATION} iconSize={20} />{' '}
              {l_presentation_mode}
            </Link>
          )}
            </LinkContainer>
          {hasRighter && (
            <Righter>
              {/* <Divider /> */}
              <Link
                href={url_blog}
                target="_blank"
                rel="noopener noreferrer"
              >
                {l_back_to_blog} <Icon icon={IconNames.SHARE} iconSize={14} />
              </Link>
            </Righter>
          )}
        </ToggleHide>
      </HeaderNavbar>
    </>
  );
};

HeaderNav.propTypes = {
  hasSaveFileOption: PropTypes.bool,
  hasOpenFileOption: PropTypes.bool,
  hasPrintOption: PropTypes.bool,
  hasExportOption: PropTypes.bool,
  hasPresentationModeOption: PropTypes.bool,
  hasRighter: PropTypes.bool,
};

export default HeaderNav;
